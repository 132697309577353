export const pricingData = {
  monthly: {
    amount: 700,
    currency: "usd",
  },
  yearly: {
    amount: 5600,
    currency: "usd",
  },
};
